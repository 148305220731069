<template>
  <CContainer fluid>
    <CRow class="row d-flex justify-content-between display-card">
      <CCol class="col-lg-12 col-md-12 px-2">
        <CCard class="p-3 m-0 mb-3">
          <label for="tanggal_daftar" class="text-dark">Tanggal </label>
          <date-picker
            id="tanggal_daftar"
            class="w-100"
            format="dddd, DD MMMM YYYY"
            v-model="filter.tanggal"
            :clearable="false"
            @change="getData"
          ></date-picker>
        </CCard>
      </CCol>
      <CCol class="col-lg-4 col-md-6 px-2">
        <CCard color="primary" style="fill: #9c4098">
          <CCardBody>
            <CRow>
              <CCol md="8">
                <h3>{{ pasien }}</h3>
                <div>Pasien</div>
              </CCol>
              <CCol md="4" align="right">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-person-vcard" viewBox="0 0 16 16">
                  <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
                  <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12z"/>
                </svg>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-lg-4 col-md-6 px-2">
        <CCard color="primary" style="fill: #9c4098">
          <CCardBody>
            <CRow>
              <CCol md="8">
                <h3>{{ pasien_umum }}</h3>
                <div>Pasien Umum Sudah Dilayani</div>
              </CCol>
              <CCol md="4" align="right">
                <CIcon name="cil-wc" size="xl"></CIcon>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-lg-4 col-md-6 px-2">
        <CCard color="primary" style="fill: #9c4098">
          <CCardBody>
            <CRow>
              <CCol md="8">
                <h3>{{ resep_diberikan }}</h3>
                <div>Resep Obat Diberikan</div>
              </CCol>
              <CCol md="4" align="right">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-capsule" viewBox="0 0 16 16">
                  <path d="M1.828 8.9 8.9 1.827a4 4 0 1 1 5.657 5.657l-7.07 7.071A4 4 0 1 1 1.827 8.9Zm9.128.771 2.893-2.893a3 3 0 1 0-4.243-4.242L6.713 5.429z"/>
                </svg>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-lg-4 col-md-6 px-2">
        <CCard color="primary" style="fill: #9c4098">
          <CCardBody>
            <CRow>
              <CCol md="8">
                <h3>{{ pasien_sudah_membayar }}</h3>
                <div>Pasien Sudah Membayar</div>
              </CCol>
              <CCol md="4" align="right">
                <i class="cil-money"></i>
                <CIcon name="cil-money" size="xl"></CIcon>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-lg-4 col-md-6 px-2">
        <CCard color="primary" style="fill: #9c4098">
          <CCardBody>
            <CRow>
              <CCol md="8">
                <h3>{{ pasien_bpjs }}</h3>
                <div>Pasien BPJS Sudah Dilayani</div>
              </CCol>
              <CCol md="4" align="right">
                <img src="/img/logo-bpjs-1.svg" alt="" srcset="" style="height: 1.8rem; filter: brightness(0) invert(1);">
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol class="col-lg-4 col-md-6 px-2">
        <CCard color="primary" style="fill: #9c4098">
          <CCardBody>
            <CRow>
              <CCol md="8">
                <h3>{{ pasien_bpjs_sudah_bridging }}</h3>
                <div>Pasien BPJS Sudah Bridging</div>
              </CCol>
              <CCol md="4" align="right">
                <img src="/img/logo-bpjs-1.svg" alt="" srcset="" style="height: 1.8rem; filter: brightness(0) invert(1);">
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- grafik -->
    <CRow>
      <CCol class="col-lg-4 col-md-12 px-2">
        <b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Kunjungan Pasien Hari Ini</strong>
            </h5>
          </template>
          <CRow>
            <CCol cols="12">
              <GrafikKunjunganHariIni 
                :data="kunjungan_pasien_hari_ini_by_jenis" 
                :backgroundColor="backgroundColor" 
              />
            </CCol>
          </CRow> 
        </b-card>
      </CCol>
      <CCol class="col-lg-8 col-md-12 px-2">
        <b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Kunjungan Pasien 7 Hari Lalu</strong>
            </h5>
          </template>
          <CRow>
            <CCol cols="12">
              <GrafikKunjungan7Hari
                :data="kunjungan_pasien_7_hari" 
                :backgroundColor="backgroundColor" 
              />
            </CCol>
          </CRow> 
        </b-card>
      </CCol>
      <CCol class="col-lg-12 col-md-12 px-2">
        <b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>Kunjungan Poliklinik</strong>
            </h5>
          </template>
          <CRow>
            <CCol cols="12">
              <GrafikPoli 
                :data="kunjungan_poliklinik" 
                :backgroundColor="backgroundColor" 
              />
            </CCol>
          </CRow> 
        </b-card>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import GrafikPoli from "./grafik_poliklinik.vue";
import GrafikKunjunganHariIni from "./grafik_kunjungan_hari_ini.vue";
import GrafikKunjungan7Hari from "./grafik_kunjungan_7_hari.vue";
export default {
  name: "Dashboard",
  components: {
    GrafikPoli,
    GrafikKunjunganHariIni,
    GrafikKunjungan7Hari,
  },
  data() {
    return {
      filter: {
        tanggal: null,
      },
      pasien: 0,
      pasien_umum: 0,
      pasien_bpjs: 0,
      pasien_sudah_membayar: 0,
      resep_diberikan: 0,
      pasien_bpjs_sudah_bridging: 0,

      kunjungan_poliklinik: [],
      kunjungan_pasien_hari_ini_by_jenis: [],
      kunjungan_pasien_7_hari: [],

      pasien_terdaftar: null,
      kunjungan_poli: null,
      kunjungan_jenis: null,
      backgroundColor: [
        [
          "rgba(156, 64, 152, 1)",
          "rgba(156, 64, 152, 0.9)",
          "rgba(156, 64, 152, 0.8)",
          "rgba(156, 64, 152, 0.7)",
          "rgba(156, 64, 152, 0.6)",
          "rgba(156, 64, 152, 0.5)",
          "rgba(156, 64, 152, 0.4)",
          "rgba(156, 64, 152, 0.2)",
          "rgba(156, 64, 152, 0.2)",
          "rgba(156, 64, 152, 0.1)",
          "rgba(156, 64, 152, 0)",
        ],
        [
          "rgba(213, 47, 101, 1)",
          "rgba(213, 47, 101, 0.9)",
          "rgba(213, 47, 101, 0.8)",
          "rgba(213, 47, 101, 0.7)",
          "rgba(213, 47, 101, 0.6)",
          "rgba(213, 47, 101, 0.5)",
          "rgba(213, 47, 101, 0.4)",
          "rgba(213, 47, 101, 0.3)",
          "rgba(213, 47, 101, 0.2)",
          "rgba(213, 47, 101, 0.1)",
          "rgba(213, 47, 101, 0)",
        ],
        [
          "rgba(64, 90, 156, 1)",
          "rgba(64, 90, 156, 0.9)",
          "rgba(64, 90, 156, 0.8)",
          "rgba(64, 90, 156, 0.7)",
          "rgba(64, 90, 156, 0.6)",
          "rgba(64, 90, 156, 0.5)",
          "rgba(64, 90, 156, 0.4)",
          "rgba(64, 90, 156, 0.3)",
          "rgba(64, 90, 156, 0.2)",
          "rgba(64, 90, 156, 0.1)",
          "rgba(64, 90, 156, 0)",
        ],
        [
          "rgba(170, 213, 47, 1)",
          "rgba(170, 213, 47, 0.9)",
          "rgba(170, 213, 47, 0.8)",
          "rgba(170, 213, 47, 0.7)",
          "rgba(170, 213, 47, 0.6)",
          "rgba(170, 213, 47, 0.5)",
          "rgba(170, 213, 47, 0.4)",
          "rgba(170, 213, 47, 0.3)",
          "rgba(170, 213, 47, 0.2)",
          "rgba(170, 213, 47, 0.1)",
          "rgba(170, 213, 47, 0)",
        ],
        [
          "rgba(124, 124, 124, 1)",
          "rgba(124, 124, 124, 0.9)",
          "rgba(124, 124, 124, 0.8)",
          "rgba(124, 124, 124, 0.7)",
          "rgba(124, 124, 124, 0.6)",
          "rgba(124, 124, 124, 0.5)",
          "rgba(124, 124, 124, 0.4)",
          "rgba(124, 124, 124, 0.3)",
          "rgba(124, 124, 124, 0.2)",
          "rgba(124, 124, 124, 0.1)",
          "rgba(124, 124, 124, 0)",
        ],
        [
          "rgba(43, 52, 153, 1)",
          "rgba(43, 52, 153, 0.9)",
          "rgba(43, 52, 153, 0.8)",
          "rgba(43, 52, 153, 0.7)",
          "rgba(43, 52, 153, 0.6)",
          "rgba(43, 52, 153, 0.5)",
          "rgba(43, 52, 153, 0.4)",
          "rgba(43, 52, 153, 0.3)",
          "rgba(43, 52, 153, 0.2)",
          "rgba(43, 52, 153, 0.1)",
          "rgba(43, 52, 153, 0)",
        ],
        [
          "rgba(255, 108, 34, 1)",
          "rgba(255, 108, 34, 0.9)",
          "rgba(255, 108, 34, 0.8)",
          "rgba(255, 108, 34, 0.7)",
          "rgba(255, 108, 34, 0.6)",
          "rgba(255, 108, 34, 0.5)",
          "rgba(255, 108, 34, 0.4)",
          "rgba(255, 108, 34, 0.3)",
          "rgba(255, 108, 34, 0.2)",
          "rgba(255, 108, 34, 0.1)",
          "rgba(255, 108, 34, 0)",
        ],
        [
          "rgba(255, 146, 9, 1)",
          "rgba(255, 146, 9, 0.9)",
          "rgba(255, 146, 9, 0.8)",
          "rgba(255, 146, 9, 0.7)",
          "rgba(255, 146, 9, 0.6)",
          "rgba(255, 146, 9, 0.5)",
          "rgba(255, 146, 9, 0.4)",
          "rgba(255, 146, 9, 0.3)",
          "rgba(255, 146, 9, 0.2)",
          "rgba(255, 146, 9, 0.1)",
          "rgba(255, 146, 9, 0)",
        ],
        [
          "rgba(255, 208, 153, 1)",
          "rgba(255, 208, 153, 0.9)",
          "rgba(255, 208, 153, 0.8)",
          "rgba(255, 208, 153, 0.7)",
          "rgba(255, 208, 153, 0.6)",
          "rgba(255, 208, 153, 0.5)",
          "rgba(255, 208, 153, 0.4)",
          "rgba(255, 208, 153, 0.3)",
          "rgba(255, 208, 153, 0.2)",
          "rgba(255, 208, 153, 0.1)",
          "rgba(255, 208, 153, 0)",
        ],
        [
          "rgba(85, 124, 85, 1)",
          "rgba(85, 124, 85, 0.9)",
          "rgba(85, 124, 85, 0.8)",
          "rgba(85, 124, 85, 0.7)",
          "rgba(85, 124, 85, 0.6)",
          "rgba(85, 124, 85, 0.5)",
          "rgba(85, 124, 85, 0.4)",
          "rgba(85, 124, 85, 0.3)",
          "rgba(85, 124, 85, 0.2)",
          "rgba(85, 124, 85, 0.1)",
          "rgba(85, 124, 85, 0)",
        ],
        [
          "rgba(255, 255, 255, 1)",
          "rgba(255, 255, 255, 0.9)",
          "rgba(255, 255, 255, 0.8)",
          "rgba(255, 255, 255, 0.7)",
          "rgba(255, 255, 255, 0.6)",
          "rgba(255, 255, 255, 0.5)",
          "rgba(255, 255, 255, 0.4)",
          "rgba(255, 255, 255, 0.3)",
          "rgba(255, 255, 255, 0.2)",
          "rgba(255, 255, 255, 0.1)",
          "rgba(255, 255, 255, 0)",
        ],
      ],
      dataPengguna: {
        labels: [],
        datasets: [
          {
            label: "Pengguna",
            data: [],
          },
        ],
      },
      data: {
        jenis_kelamin: null,
      },
      dataJenis: {
        labels: [],
        datasets: [
          {
            label: "Baru",
            data: [],
          },
          {
            label: "Lama",
            data: [],
          },
        ],
      },
      dataPoli: {
        labels: [],
        datasets: [
          {
            label: "Kunjungan",
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    tanggal_sesi(){
      return this.$store.state.tanggal_sesi
    },
  },
  watch: {
    // tanggal_sesi(newVal, oldVal){
    //   if(newVal != oldVal){
    //     this.filter.tanggal = new Date(this.tanggal_sesi)
    //   }
    // },
    'filter.tanggal'(newVal, oldVal){
      if(newVal != oldVal){
        if(this.$moment(oldVal).format('LL') != this.$moment(newVal).format('LL')) {
          this.getData()
        }
      }
    },
  },
  activated() {
    const vm = this
    vm.filter.tanggal = new Date(vm.tanggal_sesi)
    vm.get_kunjungan_jenis();
    vm.get_kunjungan_poli();
    // vm.getData()
  },
  methods: {
    async getData(){
      const vm = this
      vm.busy = false
      try {
        let res = await vm.$axios.post("kunjungan/list_dashboard_utama", {
          tanggal: vm.$moment(vm.filter.tanggal).format('YYYY-MM-DD'),
        });
        if(res.data.status == 200){
          const x = res.data.data
          vm.pasien = x.pasien_data_lengkap
          vm.pasien_umum = x.pasien_umum_sudah_dilayani
          vm.pasien_bpjs = x.pasien_bpjs_sudah_dilayani
          vm.pasien_sudah_membayar = x.pasien_sudah_membayar
          vm.resep_diberikan = x.resep_diberikan
          vm.pasien_bpjs_sudah_bridging = x.pasien_bpjs_sudah_bridging
          vm.kunjungan_poliklinik = x.kunjungan_by_poli
          vm.kunjungan_pasien_hari_ini_by_jenis = x.kunjungan_by_jenis_pasien
          vm.kunjungan_pasien_7_hari = x.kunjungan_by_7_hari_kebelakang
        }
        console.log('list_dashboard_utama', res)
      } catch (error) {
        vm.triggerAlert({ variant: "danger", msg: 'Terjadi kesalahan system', showing: true });
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async get_kunjungan_jenis() {
      let vm = this;
      try {
        let jenis = await vm.$axios.post("kunjungan/kunjungan_berdasarkan_jenis");
        // console.log(jenis.data.data, "jenis");
        let dataJenis = {
          labels: [],
          datasets: [
            {
              label: "Baru",
              data: [],
            },
            {
              label: "Lama",
              data: [],
            },
          ],
        };
        vm.kunjungan_jenis = jenis.data.status == 200 ? jenis.data.data : [];
        vm.kunjungan_jenis.map((item) => {
          dataJenis.labels.push(item.bulan.toUpperCase());
          dataJenis.datasets[0].data.push(item.jumlah_kunjungan_lama);
          dataJenis.datasets[1].data.push(item.jumlah_kunjungan_baru);
        });
        vm.dataJenis = dataJenis;
      } catch (err) {
        console.log(err);
      }
    },
    async get_kunjungan_poli() {
      let vm = this;
      try {
        let poli = await vm.$axios("kunjungan/kunjungan_berdasarkan_poli");
        // console.log(poli.data.data, "poli");
        let dataPoli = {
          labels: [],
          datasets: [
            {
              label: "Kunjungan",
              data: [],
            },
          ],
        };
        vm.kunjungan_poli = poli.data.status == 200 ? poli.data.data : [];
        vm.kunjungan_poli.map((item) => {
          dataPoli.labels.push(item.nama_poli);
          dataPoli.datasets[0].data.push(item.jumlah_kunjungan);
        });
        vm.dataPoli = dataPoli;
      } catch (err) {
        console.log(err);
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

body {
  font-family: "poppins";
  font-size: var(--font-size);
}
.bg-primary {
  background-color: #9c4098 !important;
}

.display-card {
  color: white;
}
</style>
