<template>
  <div class="p-2 m-0">
    <canvas id="myChart" ref="serviks" style="height: 315px; width: 100%"></canvas>
  </div>
</template>
<script>
import {Chart, registerables } from 'chart.js'
Chart.register(...registerables );
export default {
  props: [
    "data", 
    "backgroundColor", 
  ],
  data() {
    return {
      busy: false,
      myChart: null,
    }
  },
  computed: {
    
  },
  watch: {
    'data': {
      handler(data) {
        data
        // console.log('data', data)
        this.onData()
      },
      deep: true
    },
  },
  activated() {
    const vm = this
    const ctx = vm.$refs.serviks.getContext("2d");
    let factor = 1 / (vm.backgroundColor.length - 1);
    let gradient = ctx.createLinearGradient(0, 0, 0, 777);
    for (let i = 0; i < vm.backgroundColor.length; i++) {
      gradient.addColorStop(0 + i * factor, vm.backgroundColor[0][i]);
    }

    vm.myChart = new Chart(this.$refs.serviks, {
      type: 'line',
      data: {
        labels: [],
        datasets: [{
          label: null,
          data: [],
          backgroundColor: gradient,
          borderColor: vm.backgroundColor[0],
          fill: true,
          hoverOffset: 4,
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        tension: 0.3,
        plugins: {
          legend: {
            display: false,
          },
          filler: {
            propagate: true,
          },
        },
      },
    })
    vm.onData()
  },
  methods: {
    async onData(){
      const vm = this
      vm.myChart.data.labels = []
      vm.myChart.data.datasets[0].data = []
      for (let i = 0; i < vm.data.length; i++) {
        const x = vm.data[i];
        vm.myChart.data.labels.push(vm.$moment(x.tanggal).format('dddd'))
        // vm.myChart.data.datasets[0].data.push(10)
        vm.myChart.data.datasets[0].data.push(x.jumlah)
      }
      // console.log('vm.myChart', vm.myChart)
      // console.log('vm.data', vm.data)
      vm.myChart.update();
    },
  },
}
</script>