<template>
  <div class="p-2 m-0">
    <canvas id="myChart" ref="serviks" style="height: 300px; width: 100%"></canvas>
  </div>
</template>
<script>
import {Chart, registerables } from 'chart.js'
Chart.register(...registerables );
export default {
  props: [
    "data", 
    "backgroundColor", 
  ],
  data() {
    return {
      busy: false,
      myChart: null,
    }
  },
  computed: {
    
  },
  watch: {
    'data': {
      handler(data) {
        data
        // console.log('data', data)
        this.onData()
      },
      deep: true
    },
  },
  activated() {
    const vm = this
    vm.myChart = new Chart(this.$refs.serviks, {
      type: 'doughnut',
      data: {
        labels: [],
        datasets: [{
          label: 'My First Dataset',
          data: [],
          backgroundColor: vm.backgroundColor,
          hoverOffset: 4
        }]
      },
      options: {
        plugins: {
          legend: {
              display: false
          },
        }
      }
    })
    vm.onData()
  },
  methods: {
    async onData(){
      const vm = this
      vm.myChart.data.labels = ['Baru', 'Lama']
      vm.myChart.data.datasets[0].data = [
        vm.data.pasien_baru,
        vm.data.pasien_lama,
        // 10,
        // 10,
      ]
      // console.log('vm.myChart', vm.myChart)
      // console.log('7 hari', vm.data)
      vm.myChart.update();
    },
  },
}
</script>