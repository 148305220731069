<template>
  <div class="p-2 m-0">
    <canvas id="myChart" ref="bargrid" style="height: 300px; width: 100%"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
export default {
  props: [
    "data", 
    "backgroundColor", 
  ],
  data() {
    return {
      ctx: null,
      myChart: null,
    };
  },
  computed: {
  },
  watch: {
    'data': {
      handler(data) {
        data
        // console.log('data', data)
        this.onData()
      },
      deep: true
    },
  },
  mounted() {
    const vm = this;
    vm.ctx = vm.$refs.bargrid.getContext("2d");
    
    let factor = 1 / (vm.backgroundColor.length - 1);
    let gradient = vm.ctx.createLinearGradient(0, 0, 0, 777);
    for (let i = 0; i < vm.backgroundColor.length; i++) {
      gradient.addColorStop(0 + i * factor, vm.backgroundColor[0][i]);
    }
    vm.myChart = new Chart(vm.ctx, {
      type: "bar",
      data: {
        labels: [],
        datasets: [
          {
            label: null,
            data: [],
            backgroundColor: gradient,
            borderColor: vm.backgroundColor[0],
            fill: true,
            hoverOffset: 4,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        tension: 0.3,
        plugins: {
          legend: {
            display: false,
          },
          filler: {
            propagate: true,
          },
        },
      },
    });
    vm.onData()
  },
  methods: {
    async onData(){
      const vm = this
      vm.myChart.data.labels = []
      vm.myChart.data.datasets[0].data = []
      for (let i = 0; i < vm.data.length; i++) {
        const x = vm.data[i];
        vm.myChart.data.labels.push(x.nama_poli)
        vm.myChart.data.datasets[0].data.push(x.jumlah)
        // vm.myChart.data.datasets[0].data.push(10)
      }
      // console.log('vm.myChart', vm.myChart)
      vm.myChart.update();
    },
  },
};
</script>

<style scoped></style>
